<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.template.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.template.list.title"></app-i18n>
      </h1>

      <app-template-list-toolbar></app-template-list-toolbar>
      <app-template-list-filter></app-template-list-filter>
      <app-template-list-table></app-template-list-table>
    </div>
  </div>
</template>

<script>
import TemplateListFilter from '@/modules/template/components/template-list-filter.vue';
import TemplateListTable from '@/modules/template/components/template-list-table.vue';
import TemplateListToolbar from '@/modules/template/components/template-list-toolbar.vue';

export default {
  name: 'app-template-list-page',

  components: {
    [TemplateListFilter.name]: TemplateListFilter,
    [TemplateListTable.name]: TemplateListTable,
    [TemplateListToolbar.name]: TemplateListToolbar,
  },
};
</script>

<style>
</style>
